<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Vulnerability by date
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Year
                    </th>
                    <th class="text-center">
                      # of Vulnerabilities
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in vulnerabilities" :key="item.name">
                    <td>{{ item.year }}</td>
                    <td class="text-right">{{ item.noVulnerabilities }}</td>
                    <td>
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-01'">January</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 1)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-02'">
                        February
                      </router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 2)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-03'">March</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 3)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-04'">April</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 4)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-05'">May</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 5)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-06'">June</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 6)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-07'">July</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 7)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-08'">August</router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 8)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-09'">
                        September
                      </router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 9)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-10'">
                        Octomber
                      </router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 10)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-11'">
                        November
                      </router-link>
                    </td>
                    <td v-if="!(item.year == year && month < 11)">
                      <router-link :to="'/vulnerabilities-search/year-' + item.year + '/month-12'">
                        December
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-sheet height="400px">
              <chart-bar :chart-data="datacollection" :options="options" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import ChartBar from "../charts/Bar"
export default {
  name: "DashboardDashboard",
  components: { ChartBar },
  data() {
    return {
      vulnerabilities: [],
      year: 2021,
      month: 1,
      loading: true,
      datacollection: {},
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {},
  created() {
    this.getRecords()
    const dat = new Date()
    this.year = dat.getFullYear()
    this.month = dat.getMonth()
  },
  methods: {
    getRecords() {
      axios
        .get("cve_vulnerability_by_date")
        .then(response => {
          //this.$log("products list ", response)
          this.vulnerabilities = response.data
          const arr = []
          const lbls = []
          this.vulnerabilities.map(itm => {
            lbls.push(itm.year)
            arr.push(itm.noVulnerabilities)
          })
          this.datacollection = {
            labels: lbls,
            datasets: [
              {
                label: "Vulnerabilities",
                backgroundColor: "#3F51B5",
                data: arr
              }
            ]
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
td:first-child,
th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td,
th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
th {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
